.footer {
  width: 100%;
}

@media (max-width: 767px) {
  .container {
    position: relative;
    max-width: 390px;
    width: 100%;
    justify-content: space-between;
    padding-right: 17px;
    padding-left: 17px;
    background: #f4f0eb;
    margin: 0 auto;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .container {
    position: relative;
    max-width: 1440px;
    width: 100%;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 50px;
    background: #f4f0eb;
    border-top: 1px solid #4d3d3133;
    margin: 0 auto;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .title {
    font-family: "Manrope";
    font-size: 60px;
    font-weight: 200;
    line-height: 100px;
    text-align: left;
    color: #a1978e;
    padding: 50px 50px 50px 0px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .title {
    font-family: "Manrope";
    font-size: 300px;
    font-weight: 200;
    line-height: 300px;
    text-align: left;
    color: #a1978e;
    padding: 50px 50px 50px 0px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #4d3d3133;
    max-width: 100vw;
  }
}

@media (max-width: 767px) {
  .agreement {
    display: none;
  }
}
@media (min-width: 768px) {
  .agreement {
    font-family: "Manrope";
    font-size: 12px;
    font-weight: 200;
    line-height: 12px;
    text-align: left;
    color: #4d3d31;
    border-bottom: 1px solid #4d3d3133;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .bottom {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .left {
    order: 2;
  }

  .right {
    order: 1;
  }
}
@media (min-width: 768px) {
  .bottom {
    position: relative;
    display: flex;
  }
}

.left,
.right {
  flex: 1;
  padding-bottom: 56px;
}

@media (max-width: 767px) {
  .subscribe {
    font-family: "Manrope";
    font-size: 10px;
    font-weight: 200;
    line-height: 20px;
    text-align: left;
    /* margin-top: 73px; */
  }
}
@media (min-width: 768px) {
  .subscribe {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 200;
    line-height: 23.94px;
    text-align: left;
    margin-top: 73px;
  }
}

@media (max-width: 767px) {
  .button {
    padding: 11px 36.5px;
    max-width: 168px;
    background-color: #090909;
    text-transform: uppercase;
    font-family: "Manrope";
    font-size: 12px;
    font-weight: 200;
    line-height: 14.4px;
    color: #fff;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-top: 4px;
  }
}
@media (min-width: 768px) {
  .button {
    padding: 13px 52.5px;
    max-width: 200px;
    background-color: #090909;
    text-transform: uppercase;
    font-family: "Manrope";
    font-size: 12px;
    font-weight: 200;
    line-height: 14.4px;
    color: #fff;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-top: 12px;
  }
}

.button:hover {
  background: #837ba68a;
}

@media (max-width: 767px) {
  .vector {
    padding: 5px;
    background: #ffffff99;
    border-radius: 50%;
  }
}
@media (min-width: 768px) {
  .vector {
    padding: 8px;
    background: #ffffff99;
  }
}

@media (max-width: 767px) {
  .socials {
    margin-top: 40px;
    display: flex;
    gap: 8px;
  }
}
@media (min-width: 768px) {
  .socials {
    margin-top: 55.4px;
    display: flex;
    gap: 16px;
  }
}

.vector:hover {
  background: #837ba68a;
}

@media (max-width: 767px) {
  .list {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .list {
    display: flex;
    justify-content: end;
    gap: 100px;
    margin-top: 57px;
    margin-right: 150px;
  }
}

@media (min-width: 768px) {
  .links {
    display: flex;
    gap: 100px;
  }
}

@media (max-width: 767px) {
  .links,
  .links button {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 200;
    line-height: 14px;
    text-align: left;
    color: #090909;
    text-transform: uppercase;
  }
}
@media (min-width: 768px) {
  .links,
  .links button {
    font-family: "Manrope";
    font-size: 18px;
    font-weight: 200;
    line-height: 23.94px;
    text-align: left;
    color: #090909;
    text-transform: uppercase;
  }
}

.links p,
.links button {
  margin-bottom: 39px !important;
}

@media (max-width: 767px) {
  .hideOnMobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .contactInfo {
    display: flex;
    flex-direction: column;
    font-family: "Manrope";
    font-size: 12px;
    font-weight: 200;
    line-height: 18px;
    text-align: left;
    color: #4d3d31;
  }
}
@media (min-width: 768px) {
  .contactInfo {
    display: flex;
    gap: 70px;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 200;
    line-height: 23.94px;
    text-align: left;
    color: #4d3d31;
  }
}

@media (max-width: 767px) {
  .bottomRight {
    display: none;
  }
}
@media (min-width: 768px) {
  .bottomRight {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}

.contactButton {
  padding: 23px 11px 18px 11px;
  background: #837ba6;
  box-shadow: 0 0 0 9.7px #2a405d0f;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  text-transform: uppercase;
}

.contactButton:hover {
  background: #554e75;
  box-shadow: 0 0 0 15px #2a405d22;
}
